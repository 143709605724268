/* Slider */
var carousel_resize_lock = false;
$("document").ready(function(){
    /* Navigation */
    $(".navigation--toggle").click(function(){
        $(this).parents(".top-navigation").toggleClass("open");
        $("body").toggleClass("navigation-open");
    });
    $(".navigation li.page_item_has_children > a").click(function(e){
        if($(window).width() > 992 && $(window).width() > $(window).height()) {
            return true;
        }
        e.preventDefault();
        $(this).next(".children").slideToggle();
    });
    /* Navigation Mobile Effects */
    var i = 0.4;
    var transition_delay_css = "-webkit-transition-delay: duration;-moz-transition-delay: duration;-ms-transition-delay: duration;-o-transition-delay: duration;transition-delay: duration;";
    $(".navigation .menu > ul > li.page_item").each(function(){
        var item_delay = transition_delay_css.replace(/duration/g,i+"s");
        $(this).attr("style",item_delay);
        i += 0.1;
    });
    var item_delay = transition_delay_css.replace(/duration/g,i+"s");
    $(".navigation .language-navigation ul").attr("style",item_delay);

    /* Slider */
    $(".owl-carousel").owlCarousel({
        "items": 1,
        "loop": true,
        "autoplay": true
    });
    $(".owl-carousel").on('translate.owl.carousel', function(event) {
        $(".playing").removeClass("playing");
        $(this).find("video").trigger("pause");
    });
    $(".slide--play-overlay").click(function(){
        var parent = $(this).parents(".slide");
        var video = parent.find("video");
        parent.toggleClass("playing");
        video.trigger( video.prop('paused') ? 'play' : 'pause');
    });
    $("video").bind("ended",function(){
        var parent = $(this).parents(".slide");
        parent.removeClass("playing");
    });

    /* Quote Images */
    //Set position of dots at bottom of image - only for mobile portrait
    if($(window).width() < 992 && $(window).width() < $(window).height()){
        var img_height = $(".quote-images img").height();
        $(".quote-images .owl-dots").css("top", img_height-parseInt($(".quote-images .owl-dots").css("bottom"))-5);
        $(".quote-images .owl-dots").css("bottom", "auto");
    }

    /*window.sr = ScrollReveal();
    sr.reveal('.module .reveal', {reset: true});
    sr.reveal('.module .reveal-sequence', 250);*/

    var controller = new ScrollMagic.Controller({globalSceneOptions: {

    }});

    var modules = document.querySelectorAll(".module");
    for(var i = 0; i < modules.length; i++){
        new ScrollMagic.Scene({
            triggerElement: modules[i],
            triggerHook: "onEnter",
            offset: "200"
        })
            .setClassToggle(modules[i], "scroll-active")
            //.addIndicators()
            .addTo(controller);
    }

    $(".accordion--toggle").click(function(){
        var target = $(this).attr("data-target");
        var parent = $(this).parents(".accordion");
        var target_element = parent.find("#accordion-entry-"+target);
        var target_element_body = parent.find("#accordion-entry-body-"+target);
        if(target_element.hasClass("open")){
            target_element.removeClass("open")
            target_element_body.slideUp('fast');
        }else{
            if(parent.find(".accordion--entry.open").length > 0){
                parent.find(".accordion--entry.open").find(".accordion--entry--body").slideUp('fast');
                parent.find(".accordion--entry.open").removeClass("open");
                parent.find("#accordion-entry-"+target).addClass("open");
                parent.find("#accordion-entry-body-"+target).slideDown('fast');
            }else{
                parent.find("#accordion-entry-"+target).addClass("open");
                parent.find("#accordion-entry-body-"+target).slideDown('fast');
            }
        }
    });
});

$(window).resize(function() {
    carousel_resize_lock = true;
    if($(window).width() > 992 && $(window).width() > $(window).height()) {
        $(".quote-images .owl-dots").removeAttr("style");
    }
});